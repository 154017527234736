input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error-field {
    border-color: red !important;
}

.error-msg {
    color: red;
    font-style: italic;
    font-size: smaller;
    font-weight: 500;
}
